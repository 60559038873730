<template>
  <div id="app">
    <router-view/>
    <ToTop/>
  </div>
</template>

<script>
//导入公共样式
import commonStyle from '@/style/common.css'
import ToTop from '@/components/common/ToTop.vue'
export default {
  name: 'App',
  components:{
    ToTop
  }
}
</script>

