import Vue from 'vue'
import VueRouter from 'vue-router'

import {getDevice} from '@/utils/index.js'

Vue.use(VueRouter)

const device=getDevice();

const routes = [
  {
    path: '/',
    name: 'Home',
    component:() => device=='pc'?import('../pages/Home.vue'):import('../pages/HomeMobile.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component:() => device=='pc'?import('../pages/Home.vue'):import('../pages/HomeMobile.vue')
  },
  {
    path: '/service/:page',
    name: 'Service',
    component:() => device=='pc'?import('../pages/Service.vue'):import('../pages/ServiceMobile.vue')
  },
  // api
  {
    path: '/api/loginer_user_post',
    name: 'loginer_user_post',
    component:() => import('../api/loginer_user_post.vue')
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
