import $ from 'jquery'

function getDevice(){
    if($(window).width()<=800){
        return 'mobile';
    }else{
        return 'pc';
    }
}

export {getDevice}