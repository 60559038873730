<template>
    <div class="to-top"  @click="topTop">
        <Icons :color="color" name="arrow-top-circle"/>
    </div>
</template>
<script>
import Icons from './Icons.vue'
import $ from 'jquery'
export default {
    components:{
        Icons
    },
    data(){
        return {
            color:'#666',
        }
    },
    methods:{
        topTop(){
            $(document).scrollTop(0);
        }
    }
}
</script>
<style scoped lang="less">
.to-top{
    position: fixed;
    bottom:30px;
    right:30px;
    z-index:5;
    width:30px;
    height:30px;
    cursor: pointer;
}
@media screen and (max-width: 600px){
    .to-top{
        bottom:20px;
        right:10px;
        width:25px;
        height:25px;
    }
}
</style>