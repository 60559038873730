import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:null,
  },
  mutations: {
    setAtvInOrder(state,order){
      state.atvInOrder=order;
    },
  },
  actions: {
  },
  modules: {
  }
})
