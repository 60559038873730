import { render, staticRenderFns } from "./ToTop.vue?vue&type=template&id=2634e2b4&scoped=true"
import script from "./ToTop.vue?vue&type=script&lang=js"
export * from "./ToTop.vue?vue&type=script&lang=js"
import style0 from "./ToTop.vue?vue&type=style&index=0&id=2634e2b4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2634e2b4",
  null
  
)

export default component.exports